@use '../../styles/breakpoints' as *;
@use '../../styles/colors' as *;
.txFlexRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 72px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $gray-300;
  }
}

.borderBottom {
  border-bottom: 1px solid $grayCool-200;
}

.title {
  width: 20%;
  margin-right: 2rem;
  margin-left: 1rem;
}

.value {
  width: 100%;
  display: flex;
  align-items: center;
}

.skeleton.title {
  width: 10%;
  margin-right: 2rem;
  margin-left: 1rem;
  height: 1rem;
  border-radius: 0.15rem;
}

.skeleton.value {
  width: 9rem;
  height: 1rem;
  border-radius: 0.15rem;
}

.content {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  align-items: center;
  font-family: 'IBM Plex Mono', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.copy {
  margin-left: 0.7rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.fullDate {
  color: $gray-500;
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 14px;
}

.truncate {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.subValue {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;

  color: $gray-500;
  margin-left: 1rem;
}

.mobile {
  display: none;
}

.status {
  border-radius: 4px;
  height: 35px;
  padding: 0px 6px;
  background: $green-50;
  color: $green-700;
}

@media screen and (max-width: $xlg) {
  .value {
    flex-direction: row;
  }
  .truncate {
    width: auto;
  }
}

@media screen and (max-width: 984px) {
  .value {
    flex-direction: row;
  }
  .truncate {
    width: 400px;
  }
}

@media screen and (max-width: 770px) {
  .truncate {
    width: 300px;
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .fullDate {
    display: none;
  }
  .value {
    width: auto;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .title {
    width: 100%;
    margin-right: 0;
  }

  .truncate {
    width: 200px;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1rem;
  }
  .desktop {
    display: none;
  }
  .subValue {
    margin-left: 0;
  }
  .txFlexRow {
    height: 80px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 320px) {
  .value {
    flex-wrap: wrap;
  }
  .truncate {
    width: 140px;
  }
}

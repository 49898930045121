@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.fullWidth2 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
  grid-template-rows: 25% 65% 10%;
}

.fullWidth3 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
  grid-template-rows: 10% 90%;
}
.row1 {
  grid-row: 1/1;
  grid-column: 2 / span 12;
}
.row2 {
  grid-row: 2/2;
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}
.column1 {
  grid-column-start: 1;
  grid-column-end: span 10;
}
.column2 {
  grid-column-start: 11;
  grid-column-end: span 3;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
}
.column2 > * {
  width: 75%;
}
.flexRow1 {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
}

.flexTxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
}

.txGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 2rem;
}

.txFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
}

.borderBottom {
  border-bottom: 1px solid $grayCool-200;
}

.searchMobile {
  display: none;
}

.tokenImage {
  width: 20px;
  height: 20px;
  
  background: #FFFFFF;

  border: 1px solid $gray-300;
  border-radius: 24px;
}

@media screen and (max-width: $sm) {
  .searchMobile {
    display: flex;
    flex-direction: column;
  }
  .subheader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
    background: $grayCool-100;
  }
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10rem;
  }
  .flexRowBottom {
    margin-left: 2rem;
    width: 85%;
  }
  .flexRowTop {
    width: 92%;
  }
  .fullWidth3 {
    display: flex;
    flex-direction: column;
  }
  .row1 {
    height: 3.5rem;
    margin-bottom: 1.7rem;
  }
  .row2 {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }
  .row4 {
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .spanContent {
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .column2 {
    gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 3rem;
  }
  .column2 > * {
    width: 100%;
  }
  .column1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .flexRow1 {
    margin-left: 1.5rem;
  }

  @supports (-webkit-touch-callout: none) {
    .subheader {
      flex-direction: column;
      padding-left: 1rem;
      padding-bottom: 1rem;
      height: auto;
    }
  }
}

@media screen and (max-width: $xlg) {
  .fullWidth3 {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
  }

  .row1 {
    height: 3rem;
    margin-bottom: 1.5rem;
  }
  .row2 {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-device-width: 414px) {
  .row1 {
    margin-bottom: 16px;
  }
  .column2 {
    margin-left: 2rem;
  }
}
@media screen and (max-width: 320px) {
  .flexRow1 {
    margin-left: 0;
  }
  .column2 {
    margin-left: 0;
  }
}

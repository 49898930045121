@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2;
}

.container {
  max-width: 508px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.24);
  border-radius: 8px;
}

.innerContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.separate {
  display: flex;
  flex-direction: column;
  padding: 24px 4px;
}

.modalContent {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dateSelection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.dateFrame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}

.flexRow {
  display: flex;
}

.inputBox {
  width: 40%;
}

.date {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.title {
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-block-start: 0;
  margin-block-end: 0;

  color: #174cd3;
}

.text {
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.66);
}

.description {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.calendarElement {
  position: fixed;
}
.changeRange {
  margin-top: 1rem;
}
.bold {
  margin-left: 0.5rem;
  font-weight: 600;
}

.dateSelectors {
  display: flex;
  width: 100%;
  gap: 15px;

  * {
    flex: 1;
  }
}

.inputs {
  border: 1px solid rgba(0, 0, 0, 0.24) !important;
  color: rgba(black, 0.88) !important;
  padding: 12px 0px !important;
}

.label {
  color: rgba(0, 0, 0, 0.66) !important;
}

.dropdownButton {
  width: 100%;
  border-radius: 4px !important;
  border-color: rgba(0, 0, 0, 0.24) !important;
  justify-content: space-between !important;
  padding: 12px !important;
  height: 44px !important;
  background-color: #ffffff;
  color: rgba(black, 0.88) !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08) !important;
  }
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.timeinput {
  max-width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  gap: 12px;
}

.button {
  padding: 10px 24px;
  height: 100%;
  width: auto;
}

.close {
  background-color: white;

  &:hover {
    cursor: pointer;
  }
}

.download {
  background-color: $blue-700;
  color: $white !important;
}

.download:hover:enabled {
  background-color: $blue-600 !important;
  cursor: pointer;
}

.download:hover > a {
  background-color: $blue-600 !important;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: $md) {
  .dateSelectors {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    * {
      flex: 1;
    }
  }

  .container {
    top: 0;
    left: 0;
    transform: translateY(30%);
    width: auto;
    height: fit-content;
    margin: 0 16px;
  }

  .buttons {
    justify-content: flex-end;

    > :nth-child(n) {
      width: 50%;
    }
  }
}

@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;
.cardContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;
  gap: 0;
}
.skeleton.cardTitle {
  height: 2rem;
  width: 7rem;
  border-radius: 0.2rem 0.2rem 0 0;
}

.skeleton.cardSubTitle {
  height: 2rem;
  width: 7rem;
  border-radius: 0 0 0.2rem 0.2rem;
}

.cardTitle {
  font-size: 36px;
  color: $gray-900;
}

.cardSubTitle {
  font-size: 14px;
  color: $grayCool-500;
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;
.txnCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  border-bottom: 1px solid $gray-200;
  overflow: hidden;
  height: auto;
  min-height: 3.5rem;
  max-height: 3.5rem;
  font-family: 'IBM Plex Mono', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.txnCell > a {
  color: $blue-700;
}

.amount {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: center;
  padding-right: 1.5rem;
  padding-left: 0;
  gap: 0.5rem;
}

.txnEmptyRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  border-bottom: 1px solid $gray-200;
  overflow: hidden;
  min-height: 2.5rem;
}

.txnEmptyLastRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  overflow: hidden;
  min-height: 2.5rem;
}

.txContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.usd {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $gray-500;
}

.alignItemsLeft {
  align-items: flex-start;
  padding-left: 1.5rem;
}

.stackFromTo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  align-items: flex-start;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.stackRow {
  display: flex;
}
.alignLeft {
  align-self: flex-start;
  width: 45px;
}
.alignRight {
  align-self: flex-end;
}
.subTimestamp {
  display: none;
}

@media screen and (max-width: $lg) {
  .timestamp {
    display: none;
  }
  .subTimestamp {
    display: flex;
    align-items: flex-start;
    color: $gray-500;
  }
  .stackedIcon {
    align-self: center;
  }
  .hashWithDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .amount {
    padding-right: 0.5rem;
  }

  .hashWithDate > p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

@media screen and (max-width: 780px) {
  .enoughSpace {
    display: none;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.fullWidth {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
  grid-template-rows: 1fr 1fr 0.7fr;
}

.bgGray {
  background-color: $grayCool-100;
}

.testnetWarningContainer {
  grid-row: 1/1;
  grid-column: 1 / span 14;
}

.testnetWarning {
  grid-row: 1 / span 2;
  grid-column: 1 / span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $warning-50;
  height: 100%;
  gap: 1rem;
}

.testnetWarningText {
  padding: 1rem;
}

.header {
  grid-column-start: 2;
  grid-column-end: span 12;
  grid-row: 2/2;
}

.maxWidth {
  grid-column: 2 / span 6;
  grid-template-columns: repeat (6, 1fr);
  gap: 1rem 1rem;
  margin: 0;
}

.networkHeader {
  display: flex;
  flex-direction: column;
}

.networkHeaderGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.networkHeaderGroup .badge {
  font-family: 'Lausanne';
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  border-radius: 4px;
  background-color: $blue-100;
  color: $blue-900;
  padding: 5px 12px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar {
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: flex-end;
}

.examples {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  gap: 25px;
}

.example {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: $gray-600;
  font-weight: 400;
}

.shape {
  margin-right: 0.5rem;
}

@media screen and (max-width: $xlg) {
  .fullWidth {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header {
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: $md) {
  .rightSide {
    display: none;
  }
  .networkHeader {
    margin-left: 1rem;
  }
  .headerSearchMobile {
    display: none;
  }

  .header {
    padding-left: 1rem;
  }

  .fullWidth {
    display: flex;
    flex-direction: column;
  }
  .testnetWarning {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .rightSide {
    display: none;
  }
  .networkHeader {
    margin-left: 1rem;
  }

  .headerSearchMobile {
    display: none;
  }
}

@media screen and (min-width: $sm) {
  .fullWidth {
    padding-left: 0;
    padding-right: 0;
  }
  .maxWidth {
    min-height: 7rem;
  }
  .iconWidth {
    width: 3rem;
  }
}

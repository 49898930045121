@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;
.card {
  display: flex;
  flex-direction: column;
}

.header {
  border-top: 2px solid $grayCool-200;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 12px 0 0;
  gap: 8px;
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  font-size: 12px;
  color: $grayCool-500;
}

.title {
  color: $gray-900;
  font-size: 36px;
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 400;
  padding-right: 10px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 34px;
  height: 65px;
  font-family: 'IBM Plex Mono', sans-serif;
}

.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: $grayCool-400;
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.noText {
  margin-bottom: 1rem;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $grayCool-200;
  }
}

.line {
  width: 50%;
  height: 1rem;
  border-radius: 0.15rem;
}

.line:first-child {
  width: 100%;
  height: 0.1rem;
  background-color: $grayCool-200;
  margin-bottom: 0.5rem;
}

.line:last-child {
  width: 70%;
  height: 1rem;
  border-radius: 0.15rem;
}

.skeleton.content {
  width: 100%;
  height: 3rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: $white;
  padding: 5px 8px;
}

.positive {
  background-color: $green-400;
}

.negative {
  background-color: $red-500;
}

.value {
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: $sm) {
  .card {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: $lg) {
  .header {
    width: 90%;
  }
  .card {
    margin-bottom: 1rem;
  }
}

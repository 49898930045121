@use './colors' as *;

body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.light {
  .text {
    color: $gray-900;
  }
  .background {
    background-color: $grayCool-25;
  }
  .subheaderText {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $grayCool-800;
    margin-left: 0.5rem;
  }
  .overviewText {
    color: $grayCool-500;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
  }
  .normalLausanne {
    font-family: 'Lausanne';
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-block: 0em;
    margin-inline: 0px;
  }
  .normalPlexMono {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-block: 0em;
    margin-inline: 0px;
  }
  .headerSubtitle {
    font-family: 'Lausanne';
    font-style: 'regular';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $grayCool-500;
    margin: 0;
  }
  .networkName {
    font-family: 'Lausanne';
    font-style: 'regular';
    font-weight: 500;
    font-size: 36px;
    color: $blue-700;
    margin: 0;
  }
  .headerExamples {
    font-family: 'Lausanne';
    font-style: 'regular';
    font-weight: 300;
    font-size: 14px;
    color: $gray-600;
    margin: 0;
  }
}

.dark {
  .background {
    background-color: $gray-900;
  }
  .text {
    color: $blue-25;
  }
  .subheaderText {
  }
}

//PRIMARY COLORS

$white: #ffffff;
$black: #000000;

$gray-25: #fcfcfd;
$gray-50: #f6f9ff;
$gray-100: #f0f3ff;
$gray-200: #e8e8fb;
$gray-300: #b6b9c8;
$gray-400: #979aa8;
$gray-500: #6e717f;
$gray-600: #5a5d6b;
$gray-700: #3e4352;
$gray-800: #272b37;
$gray-900: #1b1e29;

$primary-25: #fcfaff;
$primary-50: #e6e9fb;
$primary-100: #c2c8f5;
$primary-200: #99a3ee;
$primary-300: #707ee7;
$primary-400: #5162e1;
$primary-500: #3246dc;
$primary-600: #2d3fd8;
$primary-700: #2637d3;
$primary-800: #1f2fce;
$primary-900: #1320c5;

$error-25: #fffaf5;
$error-50: #fef3f0;
$error-100: #fee3d7;
$error-200: #ffcdc2;
$error-300: #fda493;
$error-400: #f5735f;
$error-500: #e64434;
$error-600: #d12f1e;
$error-700: #af2616;
$error-800: #8f2416;
$error-900: #812e19;

$warning-25: #fffcf5;
$warning-50: #fff8dc;
$warning-100: #fef0c7;
$warning-200: #fedf89;
$warning-300: #fec84b;
$warning-400: #fdb022;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;
$warning-800: #93370d;
$warning-900: #7a2e0e;

$success-25: #f6fdf4;
$success-50: #e6f6e3;
$success-100: #c5eabe;
$success-200: #a1dd98;
$success-300: #77d174;
$success-400: #55c555;
$success-500: #19bd39;
$success-600: #18ab2e;
$success-700: #189c23;
$success-800: #1e8b17;
$success-900: #1e7100;

//SECONDARY COLORS
$grayCool-25: #fcfcfd;
$grayCool-50: #f9f9fb;
$grayCool-100: #eff1f5;
$grayCool-200: #dcdfea;
$grayCool-300: #b9c0d4;
$grayCool-400: #7d89b0;
$grayCool-500: #5d6b98;
$grayCool-600: #4a5578;
$grayCool-700: #404968;
$grayCool-800: #30374f;
$grayCool-900: #111322;

$grayTrue-25: #fcfcfc;
$grayTrue-50: #fafafa;
$grayTrue-100: #f5f5f5;
$grayTrue-200: #e5e5e5;
$grayTrue-300: #d6d6d6;
$grayTrue-400: #a3a3a3;
$grayTrue-500: #737373;
$grayTrue-600: #525252;
$grayTrue-700: #424242;
$grayTrue-800: #292929;
$grayTrue-900: #141414;

$grayWarm-25: #fdfdfc;
$grayWarm-50: #fafaf9;
$grayWarm-100: #f5f5f4;
$grayWarm-200: #e7e5e4;
$grayWarm-300: #d7d3d0;
$grayWarm-400: #a9a29d;
$grayWarm-500: #79716b;
$grayWarm-600: #57534e;
$grayWarm-700: #44403c;
$grayWarm-800: #292524;
$grayWarm-900: #1c1917;

$green-25: #f6fef9;
$green-50: #edfcf2;
$green-100: #d3f8df;
$green-200: #aaf0c4;
$green-300: #73e2a3;
$green-400: #47c75d;
$green-500: #16b364;
$green-600: #099250;
$green-700: #087443;
$green-800: #095c37;
$green-900: #064E3B;

$teal-25: #f6fefc;
$teal-50: #f0fdf9;
$teal-100: #ccfbef;
$teal-200: #99f6e0;
$teal-300: #5fe9d0;
$teal-400: #2ed3b7;
$teal-500: #15b79e;
$teal-600: #0e9384;
$teal-700: #107569;
$teal-800: #125d56;
$teal-900: #134e48;

$blue-25: #f5faff;
$blue-50: #eff8ff;
$blue-100: #d1e3ff;
$blue-200: #b2ddff;
$blue-300: #84caff;
$blue-400: #53b1fd;
$blue-500: #2e90fa;
$blue-600: #1570ef;
$blue-700: #174cd3;
$blue-800: #1849a9;
$blue-900: #194185;

$indigo-25: #f5f8ff;
$indigo-50: #eef4ff;
$indigo-100: #e0eaff;
$indigo-200: #c7d7fe;
$indigo-300: #a4bcfd;
$indigo-400: #8098f9;
$indigo-500: #6172f3;
$indigo-600: #444ce7;
$indigo-700: #3538cd;
$indigo-800: #2d31a6;
$indigo-900: #312E81;

$violet-25: #fbfaff;
$violet-50: #f5f3ff;
$violet-100: #ece9fe;
$violet-200: #ddd6fe;
$violet-300: #c3b5fd;
$violet-400: #a48afb;
$violet-500: #875bf7;
$violet-600: #7839ee;
$violet-700: #6927da;
$violet-800: #5720b7;
$violet-900: #491c96;

$purple-25: #fafaff;
$purple-50: #f4f3ff;
$purple-100: #ebe9fe;
$purple-200: #d9d6fe;
$purple-300: #bdb4fe;
$purple-400: #9b8afb;
$purple-500: #7a5af8;
$purple-600: #6938ef;
$purple-700: #5925dc;
$purple-800: #4a1fb8;
$purple-900: #3e1c96;

$pink-25: #fef6fb;
$pink-50: #fdf2fa;
$pink-100: #fce7f6;
$pink-200: #fcceee;
$pink-300: #faa7e0;
$pink-400: #f670c7;
$pink-500: #ee46bc;
$pink-600: #dd2590;
$pink-700: #c11574;
$pink-800: #9e165f;
$pink-900: #851651;

$red-25: #fff5f6;
$red-50: #fff1f3;
$red-100: #ffe4e8;
$red-200: #fecdd6;
$red-300: #fea3b4;
$red-400: #fd6f8e;
$red-500: #f63d68;
$red-600: #e31b54;
$red-700: #c01048;
$red-800: #a11043;
$red-900: #89123e;

$orange-25: #fefaf5;
$orange-50: #fef6ee;
$orange-100: #fdead7;
$orange-200: #f9dbaf;
$orange-300: #f7b27a;
$orange-400: #f38744;
$orange-500: #ef6820;
$orange-600: #e04f16;
$orange-700: #b93815;
$orange-800: #932f19;
$orange-900: #772917;

$yellow-25: #fefdf0;
$yellow-50: #fefbe8;
$yellow-100: #fef7c3;
$yellow-200: #feee95;
$yellow-300: #fde272;
$yellow-400: #fac515;
$yellow-500: #eaaa08;
$yellow-600: #ca8504;
$yellow-700: #a15c07;
$yellow-800: #854a0e;
$yellow-900: #713b12;

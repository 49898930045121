.controllerRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-column: 1 / span 5;
  margin-bottom: 3rem;
}
.arrows {
  display: flex;
  gap: 0.5rem;
}

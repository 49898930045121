@use '../../../../styles/breakpoints' as *;

.main {
  display: flex;
  width: 100%;

  flex-flow: column nowrap;
  align-items: center;

  a {
    color: #174cd3;
  }

  h3 {
    width: 100%;
    margin: 0px;

    //styleName: text-lausanne-xl/leading-7/font-medium;
    font-family: Lausanne;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #0a0d14;
  }

  .snapshotData {
    display: grid;
    width: 100%;

    grid-template-columns: 1fr 1fr;
    column-gap: 32px;

    @include respond-below(md) {
      grid-template-columns: 1fr;
      grid-auto-rows: min-content;
      row-gap: 24px;
    }

    .detailLink {
      display: flex;

      flex-flow: row nowrap;
      align-items: center;
      column-gap: 12px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .metagraphName {
      display: flex;

      flex-flow: row nowrap;
      align-items: center;
      column-gap: 8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .snapshotTimestamp {
      display: flex;

      flex-flow: column nowrap;

      .relative {
        //styleName: text-mono-sm/leading-5/font-regular;
        font-family: IBM Plex Mono;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #171a24;
      }

      .absolute {
        //styleName: text-mono-xs/leading-4/font-regular;
        font-family: IBM Plex Mono;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #6b7280;
      }
    }
  }

  .tables {
    display: flex;
    width: 100%;

    flex-flow: column nowrap;
  }
}

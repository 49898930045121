@font-face {
  font-family: 'Everett';
  src: url('../assets/fonts/Everett/Everett-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Everett Medium';
  src: url('../assets/fonts/Everett/Everett-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Everett Light';
  src: url('../assets/fonts/Everett/Everett-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-50.woff2') format('woff2');
  font-weight: 50;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-100.woff2') format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-150.woff2') format('woff2');
  font-weight: 150;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-200.woff2') format('woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-250.woff2') format('woff2');
  font-weight: 250;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-300.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-350.woff2') format('woff2');
  font-weight: 350;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-400.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-450.woff2') format('woff2');
  font-weight: 450;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-500.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-550.woff2') format('woff2');
  font-weight: 550;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-600.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-650.woff2') format('woff2');
  font-weight: 650;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-700.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-750.woff2') format('woff2');
  font-weight: 750;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-800.woff2') format('woff2');
  font-weight: 800;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-850.woff2') format('woff2');
  font-weight: 850;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-900.woff2') format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-950.woff2') format('woff2');
  font-weight: 950;
}

@font-face {
  font-family: 'Lausanne';
  src: url('../assets/fonts/Lausanne/woff2/TWKLausanne-1000.woff2') format('woff2');
  font-weight: 1000;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('../assets/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.infoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 24px;

  border-bottom: 1px solid $grayCool-200;
}

.infoHeaderTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  letter-spacing: -0.01em;
  color: $gray-900;
}

.infoHeaderSubTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $gray-600;
}

.infoHeaderSubTitle.skeleton {
  width: 100%;
  height: 2rem;
  border-radius: 0.2rem;
}

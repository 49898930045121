@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.main {
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;

  .arrows {
    display: flex;
    gap: 0.5rem;
    max-height: 32px;
  }

  .selectContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
      line-height: 20px;
      color: $gray-600;
      margin-right: 8px;
    }
  }
}

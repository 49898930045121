@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.tableContainer {
  width: 100%;
  margin-top: 2rem;
  height: 186px;

  background: $white;
  border: 1px solid $grayCool-200;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  gap: 50px;
  height: 65%;
}

.verticalLine {
  align-self: center;
  width: 1px;
  background-color: black;
  height: 60px;
  background: $gray-300;
}

.line {
  display: flex;
}

@media screen and (min-width: 300px) and (max-width: 580px) {
  .tableContainer {
    height: 250px;
  }

  .cardsContainer {
    gap: 0;
  }
}

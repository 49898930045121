@use './_base' as *;
@use './_breakpoints' as *;
@use './_fonts' as *;
@use './_colors' as *;

body,
html {
  font-family: 'Lausanne', sans-serif;
  background-color: $grayCool-25;
}

.fullWidth2 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.row {
  grid-column-start: 2;
  grid-column-end: span 12;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $grayCool-200;
  }
}

div.react-datepicker__day {
  &--selected {
    background-color: #2e73fa;
    color: white;
    font-weight: normal;
  }

  &--keyboard-selected {
    background-color: #2e73fa;
    color: white;
    font-weight: normal;
  }

  &:hover {
    background-color: rgba(#2e73fa, 0.25);
  }
}

@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.main {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $gray-500;
  
  margin-bottom: 14px;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.15s ease-in;

  &.selected {
    color: #2e73fa;
  }
}

.main {
  display: flex;
  width: 100%;

  padding-top: 20px;

  a {
    color: unset;
  }

  .track {
    display: flex;
    width: 100%;

    flex-flow: row nowrap;
    column-gap: 0.5em;

    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #4b5563;
  }

  .segment {
    &.last {
      color: #3e4fea;
    }
  }
}

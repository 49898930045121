@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;
.headerColumn {
  background-color: $grayCool-100;
  display: flex;
  height: 3.5rem;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  min-height: 2.5rem;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: $grayCool-200;
}

.topLeftBorder {
  border-top-left-radius: 8px 8px;
}

.topRightBorder {
  border-top-right-radius: 8px 8px;
}

.headerText {
  font-family: 'Lausanne';
  font-weight: 700;
  color: $grayCool-500;
  letter-spacing: 0.1em;
  font-size: 12px;
  white-space: nowrap;
}

.rightAligned {
  justify-content: flex-end;
  padding-right: 1.5rem;
}

.stackFromTo {
  display: flex;
}

@media screen and (max-width: $lg) {
  .timestamp {
    display: none;
  }

  .stackFromTo {
    display: flex;
  }
}

@media screen and (max-width: 780px) {
  .enoughSpace {
    display: none;
  }
  .direction {
    display: none;
  }
}

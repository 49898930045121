@use '../../styles/colors' as *;

.button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 18px;
  gap: 4px;

  color: $grayCool-700;

  border: 1px solid $gray-300;
  border-radius: 24px;
}

.rightIcon {
  justify-content: space-between;
  align-items: center;

  .iconHolder {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 8px;
  }
}

.button:hover {
  cursor: pointer;
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.footer {
  grid-column-start: 2;
  grid-column-end: span 12;
  background-color: $grayCool-25;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: $blue-500;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.06px;
    text-decoration-line: underline;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.footerText {
  color: $gray-500;
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.fullWidth2 {
  margin: 78px 0px 42px;
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.poweredBy {
  height: 2rem;
}

@media screen and (max-width: $xlg) {
  .fullWidth2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: $lg) {
  .fullWidth2 {
    height: 35%;
    margin-top: 2.5rem;
  }

  .footer {
    flex-direction: row;
  }
}

@media screen and (max-width: $md) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: unset;
    width: 100%;
  }
  .fullWidth2 {
    height: 35%;
    display: flex;
  }
}

@media screen and (max-width: $sm) {
  .fullWidth2 {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
    padding-bottom: 3rem;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

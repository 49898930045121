.main {
  &,
  * {
    box-sizing: border-box;
  }

  display: none;
  min-width: 284px;

  flex-flow: column nowrap;
  padding: 16px;
  gap: 8px;

  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  box-shadow: 0px 4px 6px -2px #0000000a, 0px 10px 15px -3px #00000014;

  position: absolute;
  top: calc(100% + 14px);
  right: 0px;
  z-index: 100;

  &.open {
    display: flex;
  }
}

@use '../../../styles/breakpoints' as *;

.stats,
.mainnetStats {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.stats {
  display: grid;
  width: 100%;

  grid-template-columns: repeat(4, 1fr);
  gap: 24px;

  @include respond-below(tb) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-below(md) {
    grid-template-columns: 1fr;
    margin-top: 30px;
    gap: 16px;
  }
}

.mainnetStats {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include respond-below(md) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    > * {
      width: 100%;
    }
  }
}

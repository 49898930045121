@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.main {
  display: flex;

  flex-flow: column nowrap;
  padding: 20px;
  gap: 12px;

  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -1px #00000008, 0px 4px 12px -1px #0000000a;

  .title {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.12399999797344208px;
    text-align: left;
    color: #6b7280;
  }

  .content {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4000000059604645px;
    text-align: left;
    color: #0a0d14;
  }

  .change {
    margin-left: 5px;

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.23999999463558197px;
    text-align: left;

    &.up {
      color: #059669;
    }

    &.down {
      color: #df1e25;
    }
  }
}

@use '../../styles/colors' as *;
.cardContainer {
  display: flex;
  align-items: flex-start;
  background: $white;
  border: 1px solid $grayCool-200;
  gap: 1rem;
  padding: 16px;
}

.cardContainer:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cardContainer:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

%container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 60px;
  align-items: center;
  gap: 16px;
  padding: 0px;
}

.titleContainer {
  @extend %container;
  width: 30%;
}

.infoContainer {
  @extend %container;
  width: 70%;
}

.cardTitle {
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: $grayCool-500;
}

.hash {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 12px;
  line-height: 18px;
  font-size: 12px;
}

.subValue {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $gray-500;
  margin-left: 1rem;
}

.copy {
  margin-left: 0.7rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.copied {
  position: fixed;
  bottom: 0;
  background-color: red;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 18px 0px black;
  border-radius: 8px;
  padding: 1rem;
}

.fade {
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $grayCool-200;
  }
}

.skeleton.content {
  width: 100%;
  height: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.6rem;
  border-radius: 0.2rem;
}

@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.main {
  display: flex;
  width: 100%;

  position: relative;
  overflow-x: auto;
  overflow-y: hidden;

  border-bottom: 1px solid $grayCool-200;
}

.tabs {
  display: flex;
  width: 100%;

  flex-flow: row nowrap;
  column-gap: 40px;
}

.glider {
  display: flex;
  height: 2px;

  position: absolute;

  background-color: #2e73fa;
  border-radius: 99px; // just a high number to create pill effect
  transition: 0.25s ease-out;
  top: calc(100%);
}

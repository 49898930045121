@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.dropDownList {
  position: relative;
}

.fullWidth3 {
  grid-column: 2 / span 12;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subTitle {
  margin-top: 48px;
  margin-bottom: 24px;
}

.addressOverview {
  display: flex;
  flex-direction: column;
}

.row3 {
  margin-top: 50px;
}

.row4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  .searchTokens {
    width: 400px;
  }
}

.row5 {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.row6 {
  display: flex;
  margin-bottom: 50px;

  @include respond-below(md){
    margin: 0 2em;
  }
}

%flexRow {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.flexRowBottom {
  @extend %flexRow;
  align-items: flex-end;
}

.flexRowTop {
  @extend %flexRow;
  align-items: flex-start;
  margin-top: 1rem;
}

.spanLink {
  color: blue;
  cursor: pointer;
}

.txGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  width: 100%;
}

.txFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
}

.borderBottom {
  border-bottom: 1px solid $grayCool-200;
}

.arrows {
  display: flex;
  gap: 0.5rem;
  max-height: 32px;
}

.searchMobile {
  display: none;
}

.selectorContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;
    margin-right: 8px;
  }
}

@media screen and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
  }

  .subheader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
    background: $grayCool-100;
  }
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10rem;
  }
  .flexRowBottom {
    margin-left: 2rem;
    width: 85%;
  }
  .flexRowTop {
    width: 92%;
  }
  .fullWidth3 {
    display: flex;
    flex-direction: column;
  }
  .fullWidth3NoSearchBar {
    display: flex;
    flex-direction: column;
  }
  .row1 {
    height: 3.5rem;
    margin-bottom: 1.7rem;
  }
  .row2 {
    display: flex;
  }

  .row4 {
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .row5 {
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .spanContent {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @supports (-webkit-touch-callout: none) {
    .subheader {
      flex-direction: column;
      padding-left: 1rem;
      padding-bottom: 1rem;
      height: auto;
    }
  }
}

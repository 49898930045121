@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.tokenRow {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  border-bottom: 1px solid $gray-200;
  width: 100%;
  background-color: $grayCool-25;
}
.black {
  color: $black;
}

.gray {
  color: $grayCool-500;
}

.tokenCell {
  display: flex;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $gray-900;
  }

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.tokenCell.skeleton {
  width: 97%;
  height: 30%;
  align-self: center;
  justify-self: center;
  border-radius: 0.3rem 0.3rem;
  grid-column: 1 / span 5;
}

.normalText {
  padding-left: 1.5rem;
  font-size: 14px;
}

.tokenIcon {
  width: 32px;
  height: 32px;

  background: #ffffff;
  border: 1px solid $gray-300;
  border-radius: 24px;

  margin-right: 19px;
}

.copy {
  margin-left: 8px;
  cursor: pointer;
}

.link {
  color: #174cd3;
  cursor: pointer !important;
}

@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;
.fullWidth3 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.row1 {
  grid-row: 1/1;
  grid-column: 2 / span 12;
  margin-bottom: 0.7rem;
  padding: 1.5em 0;
}

%flexRow {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.flexRowBottom {
  @extend %flexRow;
  align-items: flex-end;
}

.flexRowTop {
  @extend %flexRow;
  align-items: flex-start;
  margin-top: 1rem;
}

.row2 {
  grid-row: 2/2;
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.spanContent {
  grid-column: 2 / span 12;
}

.txGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 2rem;
}

.arrows {
  display: flex;
  gap: 0.5rem;
}

.row3 {
  grid-row: 3/3;
  grid-column: 2 / span 12;
  margin-bottom: 0.7rem;
}

.row4 {
  grid-row: 4/4;
  grid-column: 2 / span 12;
  display: flex;
}

.row5 {
  grid-row: 5/5;
  grid-column: 2 / span 12;
}

.searchMobile {
  display: none;
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
    background: $grayCool-100;
  }
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10rem;
  }
  .flexRowBottom {
    margin-left: 2rem;
    width: 85%;
  }
  .flexRowTop {
    width: 92%;
  }
  .fullWidth3 {
    display: flex;
    flex-direction: column;
  }
  .row1 {
    height: 3.5rem;
    margin-bottom: 1.7rem;
  }
  .row2 {
    display: flex;
  }
  .row4 {
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .spanContent {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @supports (-webkit-touch-callout: none) {
    .subheader {
      flex-direction: column;
      padding-left: 1rem;
      padding-bottom: 1rem;
      height: auto;
    }
  }
}

@media screen and (max-width: $xlg) {
  .fullWidth3 {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
  }

  .row1 {
    height: 3rem;
    margin-bottom: 1.5rem;
  }
  .row2 {
    display: flex;
    flex-direction: column-reverse;
  }
}

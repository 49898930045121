.main {
  display: flex;

  min-width: 93%;
  width: max-content;

  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 14px 16px;

  color: rgba(black, 0.88);
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  &:nth-child(1) {
    border-radius: 6px 6px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 6px 6px;
  }

  &:hover {
    background-color: rgba(black, 0.08);
  }

  &.icon :nth-child(1) {
    margin-right: 16px;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.container {
  display: grid;
  grid-template-columns: 1fr 0.7fr 0.6fr 0.9fr 1fr 0.7fr 1fr 1fr;
  width: 100%;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  grid-template-rows: min-content;
}

.containerSnap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  grid-template-rows: min-content;
}

.containerSmall {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 20rem));

  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
}

.homeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 3.8rem;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
}

.homeContainerMetagraph {
  display: grid;
  grid-template-columns: 1fr 1fr 1.25fr 1fr;
  grid-auto-rows: 3.8rem;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $gray-300;
  }
}

.skeleton.value {
  width: 90%;
  height: 1rem;
}

.icon {
  align-self: center;
  justify-self: flex-end;
  margin-right: 1rem;
}

.headerText {
  align-self: center;
  justify-self: flex-start;
  margin-left: 1rem;
  font-family: 'Lausanne';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $grayCool-900;
  white-space: nowrap;
  max-width: 100px;
}
.cards {
  display: none;
}

.headerCards {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dcdfea;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 3.5rem;
}

.emptyStateLabel {
  display: flex;
  height: 340px;
  width: 100%;

  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #4b5563;

  @include respond-below(md) {
    height: 140px;
    background-color: #ffffff;
    border: 1px solid #e8ebfb;
    border-radius: 6px;
  }
}

@media screen and (max-width: $lg) {
  .container {
    grid-template-columns: 1.2fr 0.6fr 0.9fr 1fr 1fr 1.3fr 1.1fr;
  }
}

@media screen and (min-width: $md) and (max-width: 780px) {
  .container {
    grid-template-columns: 1.1fr 0.5fr 1fr 1.2fr 1fr;
  }
}

@media screen and (max-width: $md) {
  .table {
    display: none;
  }
  .cards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (min-width: $md) and (max-width: $lg) {
  .container {
    grid-auto-rows: max-content;
  }
}

@media screen and (max-width: $md) {
  .icon {
    display: none;
  }
}

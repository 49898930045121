@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: white;
  border: 2px solid $grayCool-200;
  border-radius: 60px;
  transition: 0.5s;
}

.searchLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.searchIcon {
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;

  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchIcon:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid $gray-400;
  border-radius: 50%;
  transform: translate(-4px, -4px);
}

.searchIcon:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 12px;
  background: $gray-400;
  transform: translate(6px, 6px) rotate(315deg);
}

.searchInput {
  position: absolute;
  left: 60px;
  width: calc(87% - 60px);
  height: 60%;
  border: none;
  font-size: 20px;
}

.shortSearchInput {
  display: none;
}

.searchInput:focus {
  outline: none;
}

.searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 55px;
  background-color: $blue-700;
  color: $white;
  height: 90%;
  margin-right: 5px;
  width: 12%;
  cursor: pointer;
  z-index: 2;
}
.oldIos {
  display: none;
}

@media screen and (max-width: $sm) {
  .searchBar {
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
  }
  .searchButton {
    margin-top: 1rem;
    width: 100%;
  }
  .searchInput {
    display: none;
  }
  .shortSearchInput {
    display: block;
    position: absolute;
    left: 60px;
    height: 60%;
    border: none;
    font-size: 20px;
  }

  .searchBlock {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: $md) {
  .searchBar {
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
  }
  .searchButton {
    margin-top: 1rem;
    width: 100%;
  }
  .searchInput {
    display: none;
  }
  .shortSearchInput {
    display: block;
    position: absolute;
    left: 60px;
    height: 89%;
    width: calc(100% - 100px);
    border: none;
    font-size: 20px;
  }

  .shortSearchInput:focus {
    outline: none;
  }

  .searchBlock {
    display: flex;
    flex-direction: column;
  }

  @supports (-webkit-touch-callout: none) {
    .searchButton {
      width: 91%;
    }
    .normal {
      display: none;
    }
    .oldIos {
      display: flex;
      height: 40%;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .shortSearchInput {
    height: 80%;
  }
}

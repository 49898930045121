@use '../../styles/breakpoints' as *;

.main {
  display: flex;
  width: 100%;
  grid-column: 1 / span 14;

  flex-flow: column nowrap;
  align-items: center;

  a {
    color: #174cd3;
  }

  .metagraphs {
    display: flex;
    width: 100%;
    max-width: 1260px;

    padding: 20px;

    flex-flow: column nowrap;
  }

  .metagraphSiteLink {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include respond-below(md) {
      width: 110px;
    }
  }

  .metagraphLink {
    display: flex;

    align-items: center;
    gap: 12px;

    img,
    svg {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }

    @include respond-below(md) {
      gap: 4px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .metagraphType {
    display: inline-flex;

    justify-content: center;
    align-items: center;
    padding: 4px 12px;

    //styleName: Inter/xs/medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-transform: capitalize;
    color: #0a0d14;

    border-radius: 24px;

    &.public {
      background-color: #ebefff;
    }

    &.private {
      background-color: #f3f4f6;
    }
  }

  .metagraphNumber {
    //styleName: IBM Plex/Mono/leading-5/font-regular;
    font-family: 'IBM Plex Mono';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #0a0d14;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;
.content {
  grid-column: 2 / span 12;
  grid-row: 3 / 5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  justify-self: center;
  width: 40%;

  padding-top: 40px;
}

.homeButton {
  background-color: $blue-700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 48px;
  gap: 12px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 48px;
}

.homeButton:hover {
  background-color: $blue-600;
  cursor: pointer;
}

.homeText {
  color: $white;
  letter-spacing: 0.002em;
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.sorry {
  font-family: 'Lausanne';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 3rem;

  color: $gray-500;
}

.searchMobile {
  display: none;
}

@media screen and (max-width: $lg) {
  .content {
    grid-column: unset;
  }
}

@media screen and (max-width: $md) {
  .content {
    width: 100%;
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
    background: $grayCool-100;
  }
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10rem;
  }
  @supports (-webkit-touch-callout: none) {
    .subheader {
      flex-direction: column;
      padding-left: 1rem;
      padding-bottom: 1rem;
      height: auto;
    }
  }
}

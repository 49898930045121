@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.txnCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  border-bottom: 1px solid $gray-200;
  overflow: hidden;
  height: auto;
  min-height: 3rem;
  max-height: 3.5rem;
  font-family: 'IBM Plex Mono', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.txnCellLastRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  overflow: hidden;
  height: auto;
  min-height: 3rem;
  max-height: 3.5rem;
  font-family: 'IBM Plex Mono', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.gray {
  color: $grayCool-500;
}

.txnCell > a {
  color: $blue-700;
}

.txnEmptyRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  border-bottom: 1px solid $gray-200;
  overflow: hidden;
  min-height: 3rem;
}

.txnEmptyLastRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  overflow: hidden;
  min-height: 2.5rem;
}

.txContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.amount {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-left: 1.5rem;
  justify-content: center;
  gap: 0.2rem;
}

.usd {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $gray-500;
}

.date {
  font-family: 'IBM Plex Mono';
  color: $grayCool-900;
}

.stackFromTo {
  display: flex;
  width: 100%;
}

.stackRow {
  display: flex;
  width: 100%;
}

.alignItemsLeft {
  align-items: flex-start;
  padding-left: 1.5rem;
}

.alignLeft {
  align-self: flex-start;
  width: 45px;
}

.alignRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copyLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy {
  margin-left: 0.1rem;
  cursor: pointer;
}

.transactionDirectionGreen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $green-50;
  margin-right: 41px;
  border-radius: 48px;
  width: 40px;
  height: 24px;
  border: 1px solid $green-200;
  color: $green-900;

  span {
    font-size: 10px;
    font-family: IBM Plex Mono;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.transactionDirectionIndigo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $indigo-50;
  margin-right: 41px;
  border-radius: 48px;
  width: 40px;
  height: 24px;
  border: 1px solid $indigo-200;
  color: $indigo-900;

  span {
    font-size: 10px;
    font-family: IBM Plex Mono;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.subTimestamp {
  display: none;
}

.txnDirection {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: $lg) {
  .timestamp {
    display: none;
  }
  .subTimestamp {
    display: flex;
    align-items: flex-start;
    color: $gray-500;
  }
  .stackedIcon {
    align-self: center;
  }
  .hashWithDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .amount {
    padding-right: 0.5rem;
  }

  .hashWithDate > p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

@media screen and (max-width: 780px) {
  .enoughSpace {
    display: none;
  }
  .txnDirection {
    display: none;
  }
}

@media screen and (min-width: $md) and (max-width: $lg) {
  .txnCell {
    max-height: unset;
  }
}

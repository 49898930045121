@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.main {
  display: inline-flex;
  width: 100%;
  height: 1rem;
  border-radius: 0.15rem;

  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $gray-300;
  }
}

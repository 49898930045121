@use '../../../../styles/breakpoints' as *;

.main {
  display: flex;
  width: 100%;

  flex-flow: column nowrap;
  align-items: center;

  a {
    color: #174cd3;
  }

  .metagraphData {
    display: grid;
    width: 100%;

    grid-template-columns: 2fr 1fr;
    column-gap: 24px;

    @include respond-below(md) {
      grid-template-columns: 1fr;
    }

    .content {
      display: flex;
      width: 100%;
      max-width: 618px;

      flex-flow: column nowrap;

      > p {
        margin: 0px;

        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.12399999797344208px;
        text-align: left;
        color: #0a0d14;

        @include respond-below(md) {
          margin: 1em 0;
        }
      }
    }

    .title {
      display: flex;
      width: 100%;

      align-items: center;
      column-gap: 12px;

      > img {
        width: 32px;
        height: 32px;
      }

      > h2 {
        margin: 0;

        font-family: Inter;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.4000000059604645px;
        text-align: left;
        color: #0a0d14;

        @include respond-below(md) {
          font-family: Inter;
          font-size: 28px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: -0.4000000059604645px;
          text-align: left;
        }
      }
    }

    .rowsCard {
      .metagraphId {
        display: inline-flex;
        column-gap: 4px;
      }
    }
  }

  .nodeData {
    display: grid;
    width: 100%;

    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;

    @include respond-below(md) {
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
  }

  .tables {
    display: flex;
    width: 100%;

    flex-flow: column nowrap;
  }

  .fromToTransaction {
    display: flex;
    flex-flow: column nowrap;

    a {
      color: #171a24;
    }

    span {
      color: #174cd3;
    }
  }
}

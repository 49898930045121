@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;
.tableContainer {
  width: 100%;

  background: $white;
  border: 1px solid $grayCool-200;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.table {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: 56px; /* first one 56px */
  grid-auto-rows: 64px;
}

.rowVariantWhite {
  background: $white;
}
.rowVariantGray {
  background: $grayCool-25;
}

.fillerRow {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  width: 100%;
}
.tableCards {
  display: none;
}

@media screen and (max-width: 725px) {
  .tableContainer {
    display: none;
  }
  .tableCards {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.metagraphIcon {
  width: 20px;
  height: 20px;

  background: #ffffff;

  border: 1px solid $gray-300;
  border-radius: 24px;
}

.emptyStateLabel {
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;

  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #4b5563;
}

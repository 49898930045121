@use '../../styles/colors' as *;
@use '../../styles/_breakpoints' as *;

.fullWidth {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
  grid-template-rows: 1fr 1fr 0.7fr;
}

.section {
  height: 100px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid $grayCool-200;

  @include respond-below(md) {
    width: 100%;
    height: unset;
    padding-bottom: 1em;
  }
}

.subheader {
  grid-row: 2/2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include respond-below(md) {
    flex-flow: column nowrap;
  }
}

.subheaderItem {
  display: flex;
  align-items: center;
}

.buttonExport {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 18px;
  gap: 4px;

  color: $grayCool-700;
  height: 36px;

  border: 1px solid $gray-300;
  border-radius: 24px;
}

.buttonExport:hover {
  background-color: $grayCool-200;
  cursor: pointer;
}

.row {
  grid-column-start: 2;
  grid-column-end: span 12;
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .fullWidth {
    display: flex;
  }
}

@media screen and (max-width: $sm) {
  .fullWidth {
    grid-template-columns: none;
    width: 100%;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.fullWidth {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.fullWidth2 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.fila1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0px;
}

.fila2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tables {
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 49%;
  }
}

.viewAllContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 13px 0px 32px;
  > div {
    width: 49%;
  }
}

.fila3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(12, minmax(auto, 1fr));
}

.subheader {
  grid-row: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
}

.section {
  background-color: $grayCool-100;
}

.row {
  grid-column-start: 2;
  grid-column-end: span 12;
}

.tableSection {
  display: flex;
}

.viewAll {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  background-color: $grayCool-100;
  border: 1px solid $grayCool-200;
  border-radius: 48px;
  color: $blue-600;
}

.viewAllMobile {
  display: none;
}

.viewAll:hover {
  background-color: $grayCool-200;
  cursor: pointer;
  color: $blue-700;
}

.metagraphs {
  display: flex;
  width: 100%;

  flex-flow: column nowrap;
  margin-bottom: 24px;

  a {
    color: #174cd3;
  }

  .metagraphLink {
    display: flex;

    align-items: center;
    gap: 12px;

    img,
    svg {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }

    @include respond-below(md) {
      gap: 4px;

      img, svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .metagraphType {
    display: inline-flex;

    justify-content: center;
    align-items: center;
    padding: 4px 12px;

    //styleName: Inter/xs/medium;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-transform: capitalize;
    color: #0a0d14;

    border-radius: 24px;

    &.public {
      background-color: #ebefff;
    }

    &.private {
      background-color: #f3f4f6;
    }
  }

  .metagraphNumber {
    //styleName: IBM Plex/Mono/leading-5/font-regular;
    font-family: 'IBM Plex Mono';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #0a0d14;
  }
}

@media screen and (max-width: $sm) {
  .fullWidth2 {
    display: flex;
    flex-direction: column;
  }

  .fila2 {
    display: flex;
    flex-direction: column;
  }

  .tables {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }

  .viewAll {
    display: none;
  }

  .viewAllMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    background-color: $grayCool-100;
    border: 1px solid $grayCool-200;
    border-radius: 48px;
    color: $blue-600;
  }
}

@media screen and (max-width: $sm) {
  .fullWidth2 {
    display: flex;
    flex-direction: column;
  }

  .fila1 {
    margin-top: 2rem;
    height: 20%;
    margin-top: 4rem;
  }

  .fila2 {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  .tables {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }

  .viewAll {
    display: none;
  }

  .viewAllMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    background-color: $grayCool-100;
    border: 1px solid $grayCool-200;
    border-radius: 48px;
    color: $blue-600;
  }
}

@media screen and (max-width: $lg) {
  .fullWidth2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tables {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  .viewAll {
    display: none;
  }

  .viewAllMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    background-color: $grayCool-100;
    border: 1px solid $grayCool-200;
    border-radius: 48px;
    color: $blue-600;
  }
}

@media screen and (max-width: $md) {
  @supports (-webkit-touch-callout: none) {
    .fullWidth {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
    }
    .subheader {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
  .fullWidth2 {
    display: flex;
    flex-direction: column;
  }

  .fila2 {
    display: flex;
    flex-direction: column;
  }

  .tables {
    display: flex;
    flex-direction: column;
  }

  .viewAll {
    display: none;
  }

  .viewAllMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    background-color: $grayCool-100;
    border: 1px solid $grayCool-200;
    border-radius: 48px;
    color: $blue-600;
  }
}

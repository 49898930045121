@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;
.container {
  display: grid;
  grid-template-columns: 1fr 0.6fr 1fr 1fr 1fr 1fr;
  width: 100%;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  grid-template-rows: min-content;
}

.containerSnap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  grid-template-rows: min-content;
}

.homeContainer {
  display: grid;
  grid-template-columns: 0.9fr 0.6fr 1.5fr;
  grid-auto-rows: min-content;
  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
}

.icon {
  align-self: center;
  justify-self: flex-end;
  margin-right: 1rem;
}

.headerText {
  grid-column: 1/3;
  display: flex;
  align-self: center;
  justify-self: flex-start;
  margin-left: 1rem;
  font-family: 'Lausanne';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $grayCool-900;
  align-items: center;
  height: 3.5rem;
}
.cards {
  display: none;
}

@media screen and (max-width: $md) {
  .homeContainer {
    grid-template-columns: 1.4fr 0.6fr 1fr;
  }
  .table {
    display: none;
  }
  .cards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .headerCards {
    display: flex;
    justify-content: space-between;
    border: 1px solid #dcdfea;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 3.5rem;
  }
}

@media screen and (max-width: $lg) {
  .container {
    grid-template-columns: 1fr 1fr 1fr 1.1fr 1.1fr;
  }
}

@media screen and (min-width: $md) and (max-width: 780px) {
  .container {
    grid-template-columns: 1fr 0.8fr 1.2fr 1fr;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $grayCool-100;
  }

  100% {
    background-color: $gray-300;
  }
}

.skeleton.title {
  width: 10%;
  margin-right: 2rem;
  margin-left: 1rem;
  height: 1rem;
  border-radius: 0.15rem;
}

.skeleton.value {
  width: 9rem;
  height: 1rem;
  border-radius: 0.15rem;
}

.metagraphTokensOverview {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  
  > span {
    margin-left: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;

    width: 20%;
  }

  > div {
    width: 32%;
    margin-left: 2rem;
  }
}

@include respond-below(lg) {
  .metagraphTokensOverview {
    height: 100px;
    > span {
      width: 23%;
    }
    > div {
      width: 50%;
      margin-left: 0rem;
    }
  }
}

@include respond-below(md) {
  .metagraphTokensOverview {
    flex-direction: column;
    height: 100px;
    > span {
      margin: 10px 0px 10px 2rem;
      width: 100%;
    }
    > div {
      width: 90%;
      margin-left: 0rem;
    }
  }
}
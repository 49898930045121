@use '../../styles/breakpoints' as *;
.fullWidth3 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.row1 {
  grid-row: 1/1;
  grid-column: 2 / span 12;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;
  display: flex;
  align-items: flex-end;
}

.row2 {
  grid-row: 2/2;
  grid-column: 2 / span 12;
  display: flex;
}

.row3 {
  grid-row: 3/3;
  grid-column: 2 / span 12;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
}

%flexRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.flexRowBottom {
  @extend %flexRow;
  align-items: flex-end;
}

.flexRowTop {
  @extend %flexRow;
  align-items: flex-start;
}

.arrows {
  display: flex;
  gap: 0.5rem;
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .fullWidth3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .row1 {
    align-self: flex-end;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .row2 {
    display: flex;
    width: 90%;
  }
  .row3 {
    align-self: flex-end;
    margin-right: 1rem;
  }
}

@media screen and (min-width: $md) and (max-width: 805px) {
  .fullWidth3 {
    grid-template-rows: 10% 62% 10%;
  }
}

@media screen and (min-width: 805px) and (max-width: $lg) {
  .fullWidth3 {
    grid-template-rows: 10% 49% 10%;
  }
}

@use '../../styles/breakpoints' as *;

.main {
  display: flex;

  flex-flow: column nowrap;
  padding: 24px 20px;
  row-gap: 24px;

  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #d1d5db;

  &.border-record {
    display: grid;

    grid-template-columns: 1fr;
    grid-auto-rows: 64px;
    row-gap: 0px;
    padding: 0px;

    @include respond-below(md){
      grid-auto-rows: 1fr;
    }

    .row {
      display: grid;

      grid-template-columns: 159px 1fr;
      column-gap: 32px;
      padding: 0px 22px;
      align-items: center;

      border-bottom: 1px solid #e8ebfb;

      @include respond-below(md) {
        padding: 10px 22px;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
      }

      &:last-child {
        border: none;
      }
    }

    .label {
      //styleName: text-lausanne-sm/leading-5/font-medium;
      font-family: Lausanne;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.1599999964237213px;
      text-align: left;
      color: #4b5563;
    }
  }

  .row {
    display: flex;
    width: 100%;

    flex-flow: row nowrap;
    justify-content: space-between;

    @include respond-below(md) {
      flex-flow: column nowrap;
      row-gap: 8px;
    }
  }

  .label {
    display: flex;

    flex-flow: row nowrap;
    align-items: center;
    column-gap: 8px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .content {
    display: flex;

    flex-flow: row nowrap;
    align-items: center;
  }

  a {
    color: #363bd3;
  }
}

@use '../../styles/colors' as *;
@use '../../styles/breakpoints' as *;

.fullWidth2 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.fullWidth3 {
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}
.row1 {
  grid-row: 1/1;
  grid-column: 2 / span 12;
  margin-bottom: 16px;
  margin-top: 40px;
}

.row2 {
  grid-row: 2/2;
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 6.9rem)) 1fr;
}

.row3 {
  grid-row: 3/3;
  grid-column: 2 / span 12;
  margin-bottom: 16px;
  margin-top: 40px;
}

.row4 {
  grid-row: 4/4;
  grid-column: 2 / span 12;
  display: flex;
}

.row5 {
  grid-row: 5/5;
  grid-column: 2 / span 12;
}

.column1 {
  grid-column-start: 2;
  grid-column-end: span 10;
}
.column2 {
  grid-column-start: 11;
  grid-column-end: span 3;
}

%flexRow {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.flexRowBottom {
  @extend %flexRow;
  align-items: flex-end;
}

.flexRowTop {
  @extend %flexRow;
  align-items: flex-start;
  margin-top: 1rem;
}

.spanContent {
  grid-column: 2 / span 12;
}

.txGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: $white;
  border: 1px solid $grayCool-200;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 2rem;
}

.txFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
}

.borderBottom {
  border-bottom: 1px solid $grayCool-200;
}

.arrows {
  display: flex;
  gap: 0.5rem;
}

.searchMobile {
  display: none;
}

@media screen and (max-width: $sm) {
  .searchMobile {
    display: flex;
    flex-direction: column;
  }
  .subheader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: $sm) and (max-width: $md) {
  .searchMobile {
    display: flex;
    flex-direction: column;
    background: $grayCool-100;
  }
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10rem;
  }
  .flexRowBottom {
    margin-left: 2rem;
    width: 85%;
  }
  .flexRowTop {
    width: 92%;
  }
  .fullWidth3 {
    display: flex;
    flex-direction: column;
  }
  .row1 {
    height: 3.5rem;
    margin-bottom: 1.7rem;
  }
  .row2 {
    display: flex;
  }
  .row4 {
    justify-content: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .spanContent {
    width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @supports (-webkit-touch-callout: none) {
    .subheader {
      flex-direction: column;
      padding-left: 1rem;
      height: auto;
      padding-bottom: 1rem;
    }
  }
}

@media screen and (min-width: $md) and (max-width: 805px) {
  .fullWidth3 {
    grid-template-rows: 5% 6% 8% 36% 10%;
  }
}

@media screen and (min-width: 805px) and (max-width: $lg) {
  .fullWidth3 {
    grid-template-rows: 5% 6% 8% 36% 10%;
  }
}
